.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.full-width {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.margin-top-8 {
  margin-top: 8px;
}

.inherit-color {
  color: inherit;
}

.no-decorate {
  text-decoration: none!important;
}

.text-shadow {
  text-shadow: 2px 2px 8px #000000;
}

.disable-padding {
  padding: 0;
}

.secondary-text-light-mode {
  color: #707070;
}

.clickable {
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

.disable-click {
  opacity: 0.5;
  pointer-events: none;
}

.leaflet-container {
  background: rgb(214, 232, 235);
  z-index: 10;
}

.leaflet-container .leaflet-control-attribution, .leaflet-container .leaflet-control-scale {
  font-size: 9px;
}

.leaflet-attribution-flag {
  display: none!important;
}

.marker-cluster-small {
  background-color: rgb(55 166 250 / 55%)!important;
}

.marker-cluster-small div {
  background-color: rgb(55 166 250 / 95%)!important;
  color: white;
  font-weight: bold!important;
}

.text-center {
  text-align: center;
}

w3m-modal {
  z-index: 9999;
}

.light-grey-border {
  border: 1px solid #c1c1c1;
}

.icon-button {
  padding-right: 12px;
}

.outlined-icon-button {
  padding-right: 15px;
  padding-left: 10px;
}

.outlined-icon-button-no-text {
  padding-right: 8px;
  padding-left: 8px;
}

.light-text {
  font-weight: 400;
}